import React, {useEffect, useState} from 'react'
import {useCookies} from 'react-cookie'
import Amplify, {Auth} from 'aws-amplify'
import {Authenticator} from 'aws-amplify-react'

import {useGet} from '../../api/ApiUtils/hooks'
import Spinner from '../../ui/Spinner'
import {Permissions, AuthInfo} from '../../model'
import {login} from '../../service'
import AuthContext from '../AuthContext'
import Login from './Login'
import {lastPageLocalStorageKey} from './config'

type AuthProtectedProps = {
  game?: string
  environment?: string
  apiEndpoint?: string
  cookieDomain: string
  redirectEndpoint: string
  userPoolId: string
  userPoolWebClientId: string
  cognitoDomain: string
  loggedOutRedirectUrl?: string
}

export const AuthProtected: React.FunctionComponent<AuthProtectedProps> = ({
  game,
  environment,
  apiEndpoint,
  children,
  cookieDomain,
  redirectEndpoint,
  userPoolId,
  userPoolWebClientId,
  cognitoDomain,
  loggedOutRedirectUrl
}) => {
  return (
    <Authenticator hideDefault={true}>
      <AuthProtectedInternal
        game={game}
        environment={environment}
        apiEndpoint={apiEndpoint}
        cookieDomain={cookieDomain}
        redirectEndpoint={redirectEndpoint}
        children={children}
        userPoolId={userPoolId}
        cognitoDomain={cognitoDomain}
        userPoolWebClientId={userPoolWebClientId}
        loggedOutRedirectUrl={loggedOutRedirectUrl}
      />
    </Authenticator>
  )
}

export const AuthProtectedInternal: React.FunctionComponent<AuthProtectedProps> = ({
  children,
  game,
  environment,
  apiEndpoint,
  cookieDomain,
  redirectEndpoint,
  userPoolId,
  userPoolWebClientId,
  cognitoDomain,
  loggedOutRedirectUrl
}) => {
  const [status, setStatus] = useState({loggedIn: false, checking: true})
  const [, setCookie, removeCookie] = useCookies(['accessToken'])

  const useLogin = useGet<Permissions>(
    async () => {
      if (apiEndpoint && game && environment) return login(apiEndpoint, game, environment.toLowerCase())
      return []
    },
    {loadOnStart: false}
  )

  const [authInfo, setAuthInfo] = useState<AuthInfo>({username: '', email: '', name: '', groups: []})

  useEffect(() => {
    if (status.loggedIn) {
      useLogin.load()
    }
  }, [status.loggedIn])

  useEffect(() => {
    const oauth = {
      domain: cognitoDomain,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: redirectEndpoint,
      redirectSignOut: redirectEndpoint,
      responseType: 'code'
    }

    Amplify.configure({
      Auth: {
        oauth: oauth,
        region: 'us-east-1',
        userPoolId: userPoolId,
        userPoolWebClientId: userPoolWebClientId
      }
    })

    Auth.currentAuthenticatedUser()
      .then((u) => {
        setCookie('accessToken', u.signInUserSession.accessToken.jwtToken, {
          path: '/',
          domain: cookieDomain,
          secure: true
        })
        if (!status.loggedIn || status.checking) {
          const idToken = u.signInUserSession.idToken.payload

          setStatus({loggedIn: true, checking: false})
          setAuthInfo({
            username: idToken['cognito:username'],
            name: idToken.name,
            email: idToken.email,
            groups: idToken['cognito:groups']
          })
        }
      })
      .catch(() => {
        if (status.loggedIn || status.checking) {
          removeCookie('accessToken')
          setStatus({loggedIn: false, checking: false})
          setAuthInfo({username: '', email: '', name: '', groups: []})
        }
      })
  })

  if (status.checking || useLogin.loading) {
    return <Spinner fullPage={true} />
  }

  if (status.loggedIn && useLogin.done) {
    return <AuthContext.Provider value={{permissions: useLogin.data || [], authInfo: authInfo}}>{children}</AuthContext.Provider>
  } else {
    if (loggedOutRedirectUrl && !status.loggedIn && !status.checking) {
      const currentUrl = window.top.location.href
      localStorage.setItem(lastPageLocalStorageKey, currentUrl)
      window.top.location.href = loggedOutRedirectUrl
      return null
    }

    return loggedOutRedirectUrl ? null : (
      <Login
        isLoading={status.checking || useLogin.loading}
        // @ts-ignore Okta is not in the list of providers - requires library upgrade
        login={() => Auth.federatedSignIn({provider: 'Okta'})}
      />
    )
  }
}
