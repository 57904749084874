import React from 'react'
import styled from 'styled-components'

interface VerticalMarginProps {
  margin?: number
}

const Margin = styled.span`
  display: block;
`

const VerticalMargin: React.FunctionComponent<VerticalMarginProps> = ({margin = 1}) => <Margin className={`mb-${margin}`} />

export default VerticalMargin
