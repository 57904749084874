import {css, FlattenSimpleInterpolation} from 'styled-components'

import {sm, xs} from '../../constants/spacing'
import Colors from '../../constants/colors'
import {fromSmall} from '../../constants/mediaQueries'

const themes: {
  [key: string]: {
    list: FlattenSimpleInterpolation
    item: FlattenSimpleInterpolation
    itemTitle: FlattenSimpleInterpolation
    itemValue: FlattenSimpleInterpolation
  }
} = {
  vertical: {
    list: css`
      display: flex;
      flex-direction: column;
      padding: 0;
    `,
    item: css`
      padding: ${xs}px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    `,
    itemTitle: css`
      font-weight: bold;
      padding-right: ${sm}px;

      &::after {
        content: ':';
        display: inline-block;
      }
    `,
    itemValue: css`
      font-weight: normal;
    `
  },
  table: {
    list: css`
      display: flex;
      flex-direction: column;
      padding: 0;
    `,
    item: css`
      padding: 0.5em;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: nowrap;
      border-bottom: ${Colors.lightGrey} 1px solid;

      &:last-child {
        border-bottom: none;
      }

      ${fromSmall} {
        flex-direction: row;
      }
    `,
    itemTitle: css`
      font-weight: bold;
      padding-right: ${sm}px;

      &::after {
        content: ':';
        display: inline-block;
      }

      ${fromSmall} {
        flex: 0 0 14em;
      }
    `,
    itemValue: css`
      font-weight: normal;
      flex: 1 1 auto;
    `
  },
  horizontal: {
    list: css`
      display: flex;
      flex-direction: row;
      padding: 0;
    `,
    item: css`
      padding: ${xs}px 0;
      display: inline-flex;
      align-items: center;
      flex-direction: row;

      &::after {
        content: '|';
        display: inline-block;
        padding: 0 ${xs}px;
      }

      &:last-child::after {
        display: none;
      }
    `,
    itemTitle: css`
      font-weight: bold;

      &::after {
        content: ':';
        display: inline-block;
        padding-right: ${xs}px;
      }
    `,
    itemValue: css`
      font-weight: normal;
    `
  },
  pills: {
    list: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    `,
    item: css`
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: ${xs / 2}px ${xs}px ${xs / 2}px 0;
      padding: 0.3em 1em;
      background-color: ${Colors.tertiary};
      color: ${Colors.light};
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
      border-radius: 20px;
    `,
    itemTitle: css`
      display: inline-block;
      margin-right: ${xs}px;

      &::after {
        content: ':';
        display: inline-block;
      }
    `,
    itemValue: css`
      font-weight: bold;
    `
  }
}

export default themes
