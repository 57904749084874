import React from 'react'
import styled from 'styled-components'

import {sm} from '../../constants/spacing'

import {Filters} from '../types'
import FilterDropdown from '../FilterDropdown'

export interface Props<Item> {
  filters: Filters<Item>
  updateSelectedFilters: (filterId: string, selectedValues: string[]) => void
}

const FilterBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-right: ${sm}px;
  }
`

function FilterBar<Item>({filters, updateSelectedFilters}: Props<Item>) {
  return (
    <FilterBarWrapper>
      <b>Filter:</b>
      {filters
        .filter((filter) => filter.values.length > 0)
        .map((filter) => (
          <FilterDropdown<Item> key={filter.name} filter={filter} onSelectionChange={updateSelectedFilters} />
        ))}
    </FilterBarWrapper>
  )
}

export default FilterBar
