import axios, {AxiosResponse, AxiosError} from 'axios'
import {toast} from 'react-toastify'

import ApiError from '../../errors/ApiError'
import {refreshToken} from '../../service'
import {authRequestInterceptor, responseErrorInterceptor} from './interceptors'

interface ServiceOptions {
  baseUrl: string
  useAuthHeaders?: boolean
}

interface CallOptions {
  url: string
  params?: {[key: string]: any}
  headers?: {[key: string]: string}
  validateStatus?: (status: number) => boolean
  transformResponse?: (data: any, headers?: any) => any
  transformRequest?: (data: any, headers?: any) => any
}

interface CallOptionsWithData extends CallOptions {
  data?: {[key: string]: any} | string
}

type Method = 'POST' | 'GET' | 'PUT' | 'HEAD' | 'DELETE' | 'PATCH' | 'OPTIONS'

export type SuccessResponse = AxiosResponse

export type ErrorResponse = AxiosError

export type Service = {
  get: (options: CallOptions) => Promise<SuccessResponse>
  delete: (options: CallOptions) => Promise<SuccessResponse>
  options: (options: CallOptions) => Promise<SuccessResponse>
  head: (options: CallOptions) => Promise<SuccessResponse>
  post: (options: CallOptionsWithData) => Promise<SuccessResponse>
  put: (options: CallOptionsWithData) => Promise<SuccessResponse>
  patch: (options: CallOptionsWithData) => Promise<SuccessResponse>
}

const errorMessages: {[statusCode: number]: string} = {
  401: 'You are not authorised to do this operation',
  403: 'You are not authorised to do this operation'
}

const getErrorData = (error: AxiosError) => {
  return error.response && error.response.data
    ? {
        status: error.response.status,
        errorCode: error.response.data.errorCode,
        message: error.response.data.message || error.response.data || error.message
      }
    : {message: error.message, status: error.response && error.response.status}
}

export const service = ({baseUrl, useAuthHeaders = true}: ServiceOptions): Service => {
  const instance = axios.create({
    baseURL: baseUrl
  })

  // useAuthHeaders is temporary, until all services have upgraded common to accept the new headers
  instance.interceptors.request.use(authRequestInterceptor(useAuthHeaders).success)
  instance.interceptors.response.use(responseErrorInterceptor().success, responseErrorInterceptor().error)

  const fetch = async (method: Method, options: CallOptionsWithData, attempt: number = 0): Promise<SuccessResponse> => {
    try {
      const {url, params, data, headers, validateStatus, transformResponse, transformRequest} = options

      const response = await instance.request({
        url,
        method,
        params,
        data,
        headers,
        // This syntax is needed so we don't pass the variables at all if they're not defined. Axios recognises
        // them even if they are undefined, so we don't want that
        ...(validateStatus ? {validateStatus} : {}),
        ...(transformResponse ? {transformResponse} : {}),
        ...(transformRequest ? {transformRequest} : {})
      })

      return response
    } catch (error) {
      if (error?.response?.status === 401 && attempt === 0) {
        try {
          await refreshToken()
          const response = await fetch(method, options, attempt + 1)
          return response
        } catch (error) {
          throw new ApiError(getErrorData(error))
        }
      }

      if (error.response && errorMessages[error.response.status]) {
        toast.error(errorMessages[error.response.status])
      }

      throw new ApiError(getErrorData(error))
    }
  }

  return {
    get: (options: CallOptions) => fetch('GET', options),
    delete: (options: CallOptions) => fetch('DELETE', options),
    options: (options: CallOptions) => fetch('OPTIONS', options),
    head: (options: CallOptions) => fetch('HEAD', options),
    post: (options: CallOptionsWithData) => fetch('POST', options),
    put: (options: CallOptionsWithData) => fetch('PUT', options),
    patch: (options: CallOptionsWithData) => fetch('PATCH', options)
  }
}
