import React, {FC} from 'react'
import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

import {sm, md, xs} from '../../constants/spacing'
import Colors from '../../constants/colors'
import zIndexes from '../../constants/zIndexes'
import {Spinner} from 'reactstrap'

type Info = {
  title: string
  value: string
}

export type SearchResult = {
  id: string
  title: string
  link: string
  index?: number
  infoItems?: Array<Info>
}

export interface Props {
  results: Array<SearchResult>
  count?: number
  linkText?: string
  loading?: boolean
  floating?: boolean
  close?: () => void
}

const Wrapper = styled.div<{$floating: boolean}>`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${zIndexes.top};

  ${(props) =>
    props.$floating
      ? css`
          overflow: visible;
          height: 0;
        `
      : ''}
`

const Loading = styled.section`
  width: 100%;
  text-align: center;
`

const CloseButton = styled.button`
  display: block;
  appearance: none;
  border: none;
  background: none;
  margin-left: auto;
  color: ${Colors.grey};
`

const Results = styled.section<{$floating: boolean}>`
  width: 100%;
  background-color: ${Colors.light};
  padding: ${md}px 0;

  ${(props) =>
    props.$floating
      ? css`
          position: absolute;
          top: 0;
          left: 0;
          max-height: ${window.innerHeight - 300}px;
          overflow: auto;
          padding: ${md}px;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
          border: ${Colors.lightGrey} 1px solid;
          border-radius: 0.3rem;
        `
      : ''}
`

const ResultCount = styled.div`
  margin: 0 0 ${md}px 0;
  color: ${Colors.grey};
  font-size: 0.7rem;
`

const NoResults = styled.div`
  font-size: 1rem;
`

const Result = styled.article`
  width: 100%;
  height: auto;
  margin-bottom: ${md}px;
  background-color: ${Colors.light};
  border: ${Colors.lightGrey} 1px solid;
  border-radius: 0.3rem;
  box-shadow: 1px 1px 3px ${Colors.lightGrey};
  font-size: 0.8rem;
`

const Title = styled.h3`
  padding: ${sm}px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.1em;
  margin: 0;
`

const TitleText = styled.span``

const TitleLink = styled.span`
  align-self: flex-end;
  color: ${Colors.secondary};

  &:link {
    text-decoration: none;
  }

  &:hover,
  &:active {
    text-decoration: underline;
  }
`

const InfoList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 ${sm}px ${sm}px;
`

const InfoItem = styled.li`
  margin: 0 ${xs}px ${xs}px 0;
  padding: ${xs}px ${md}px;
  background-color: ${Colors.tertiary};
  color: ${Colors.light};
  border-radius: 20px;
`

const InfoItemTitle = styled.span`
  display: inline-block;
  margin-right: ${xs}px;
`

const InfoItemValue = styled.span`
  font-weight: bold;
`

/**
 * A list of search results that can be displayed as a panel on top of the existing page.
 */
const SearchResults: FC<Props> = ({results, count, close, linkText = 'View more', loading = false, floating = false}: Props) => {
  return (
    <Wrapper data-testid="search-results" $floating={floating}>
      <Results data-testid="search-results-list" $floating={floating}>
        {loading ? (
          <Loading data-testid="search-results-loading">
            <Spinner />
          </Loading>
        ) : (
          <>
            {floating && close && (
              <CloseButton onClick={close} type="button" title="Close">
                <i className="fa fa-close" />
              </CloseButton>
            )}

            {count && count > 0 ? (
              <ResultCount>
                Found {count} {count > 1 ? 'results' : 'result'}
              </ResultCount>
            ) : null}

            {(count && count === 0) || (results && results.length === 0) ? <NoResults>No results found for your search.</NoResults> : null}

            {results.map(({id, title, index, link, infoItems}: SearchResult) => (
              <Result data-testid="search-results-item" key={id}>
                <Title>
                  <TitleText>
                    {title} {typeof index !== undefined && <>#{index}</>}
                  </TitleText>
                  <TitleLink>
                    <Link to={link}>{linkText}</Link>
                  </TitleLink>
                </Title>

                {infoItems && (
                  <InfoList>
                    {infoItems.map((item: Info, index) => (
                      <InfoItem key={index}>
                        <InfoItemTitle>{item.title}</InfoItemTitle> <InfoItemValue>{item.value}</InfoItemValue>
                      </InfoItem>
                    ))}
                  </InfoList>
                )}
              </Result>
            ))}
          </>
        )}
      </Results>
    </Wrapper>
  )
}

export default SearchResults
