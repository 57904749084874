import React, {Component} from 'react'
import {Button, Form, FormFeedback, Input} from 'reactstrap'
import styled from 'styled-components'

const Icon = styled.i`
  color: blue;
  cursor: pointer;
  margin-left: 0.25rem;
`

type EditableStringProps = EditableStringBaseProps | EditableStringWithValidation

type EditableStringBaseProps = {
  initialValue: string
  onSave: (value: string) => void
  maxLength?: number
  disabled?: boolean
}

const hasValidation = (props: EditableStringBaseProps | EditableStringWithValidation): props is EditableStringWithValidation => {
  return (props as EditableStringWithValidation).isValidFunc !== undefined
}

type EditableStringWithValidation = EditableStringBaseProps & EditableStringValidationProps

type EditableStringValidationProps = {
  isValidFunc: (value: string) => boolean
  invalidText: string
}

type EditableStringState = {
  value: string
  editing: boolean
}

export default class EditableString extends Component<EditableStringProps, EditableStringState> {
  state: EditableStringState = {
    value: this.props.initialValue,
    editing: false
  }

  save = () => {
    this.setState({editing: false})
    this.props.onSave(this.state.value)
  }

  edit = () => {
    this.setState({editing: true})
  }

  cancel = () => {
    this.setState({editing: false, value: this.props.initialValue})
  }

  componentDidUpdate(prevProps: Readonly<EditableStringProps>) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({value: this.props.initialValue, editing: false})
    }
  }

  render() {
    const {initialValue, maxLength, disabled} = this.props
    const {value, editing} = this.state
    const invalid = hasValidation(this.props) ? !this.props.isValidFunc(value) : false
    const invalidText = hasValidation(this.props) ? this.props.invalidText : ''

    return (
      <div>
        {editing ? (
          <Form
            inline
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
              this.save()
            }}
          >
            <Input
              value={value}
              onChange={(e) => this.setState({value: e.target.value})}
              autoFocus
              maxLength={maxLength}
              invalid={invalid}
            />
            <Button className="mr-1 ml-1" color="primary" onClick={this.save} disabled={value === initialValue || invalid}>
              <i className="fa fa-check" title="Save" />
            </Button>
            <Button onClick={this.cancel}>
              <i className="fa fa-close" title="Cancel" />
            </Button>
            {invalid && <FormFeedback>{invalidText}</FormFeedback>}
          </Form>
        ) : (
          <span>
            {value.toString()} {!disabled && <Icon className="fa fa-edit" onClick={this.edit} />}
            {invalid && <div className="text-danger small">{invalidText}</div>}
          </span>
        )}
      </div>
    )
  }
}
