// Palette: https://coolors.co/ff7500-05a8aa-ffffff-06070e-605856

enum Colors {
  light = '#FFFFFF',
  dark = '#06070e',
  accent = '#ff7500',
  primary = '#ff7500',
  secondary = '#05a8aa',
  tertiary = '#17a2b8',
  neutral = '#007bff',
  success = '#28a745',
  info = '#17a2b8',
  warning = '#ffc107',
  danger = '#dc3545',
  white = '#ffffff',
  black = '#06070e',
  grey = '#424242',
  lightGrey = '#E9E9E9'
}

export default Colors
