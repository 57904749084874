import React from 'react'
import styled, {css, keyframes} from 'styled-components'

import {fromSmall} from '../../constants/mediaQueries'
import Colors from '../../constants/colors'
import Background from './assets/Background'
import Button from './assets/Button'

interface Props {
  isLoading?: boolean
  login: () => Promise<any>
}

const glow = keyframes`
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.2);
  }
  100% {
    filter: brightness(1);
  }
`

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${window.innerHeight}px;
  width: 100vw;
  background-color: ${Colors.grey};
`

const Content = styled.div`
  position: relative;
  overflow: hidden;
  border: ${Colors.dark} 1px solid;
  width: 350px;
  height: 300px;

  ${fromSmall} {
    width: 680px;
    height: 455px;
  }
`

const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: -12%;
  width: 100%;
  height: 100%;
  z-index: 0;

  > svg {
    height: 100%;
  }

  ${fromSmall} {
    top: 0;
    left: 0;
  }
`

const ButtonText = styled.span`
  z-index: 30;
  position: relative;
  display: block;
  color: ${Colors.light};
  text-shadow: rgba(0, 0, 0, 0.4) 1px 1px 0;
  font-family: 'Fortis', Helvetica, Arial, sans-serif;
`

const ButtonBackground = styled.span`
  z-index: 20;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const SignInButton = styled.button<{$isLoading: boolean}>`
  position: relative;
  appearance: none;
  border: none;
  background-color: transparent;
  z-index: 10;
  cursor: pointer;
  padding-top: 3px;
  transform-style: preserve-3d;
  perspective: 500px;
  transition: filter 1s ease-in-out;
  animation: ${(props) =>
    props.$isLoading
      ? css`
          ${glow} 2s infinite
        `
      : 'none'};

  font-size: 11px;
  width: 200px;
  height: 45px;
  top: 225px;
  left: 75px;

  ${fromSmall} {
    font-size: 16px;
    width: 300px;
    height: 65px;
    padding-top: 3px;
    top: 342px;
    left: 180px;
  }

  & ${ButtonBackground}.pressed {
    display: none;
  }

  ${ButtonText} {
    transform: rotateY(-20deg) rotateY(20deg) rotateZ(2deg) translateZ(1px);
  }

  &:hover,
  &:focus {
    outline: none;
    filter: brightness(1.2);
  }

  &:active {
    & ${ButtonBackground} {
      display: none;
    }

    & ${ButtonBackground}.pressed {
      display: block;
    }

    ${ButtonText} {
      transform: rotateY(-20deg) rotateY(20deg) rotateZ(2deg) translateZ(1px) translateX(4px) translateY(-2px);
    }
  }
`

const empty = () => {}

const Login: React.FC<Props> = ({isLoading = false, login}: Props) => (
  <Container>
    <Content>
      <BackgroundContainer>
        <Background />
      </BackgroundContainer>

      <SignInButton $isLoading={isLoading} onClick={login} onTouchStart={empty}>
        <ButtonBackground className="pressed" aria-hidden="true">
          <Button pressed={true} />
        </ButtonBackground>
        <ButtonBackground aria-hidden="true">
          <Button pressed={false} />
        </ButtonBackground>
        <ButtonText>Sign in</ButtonText>
      </SignInButton>
    </Content>
  </Container>
)

export default Login
