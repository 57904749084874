import React from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import PropTypes from 'prop-types'
import {confirmable, ReactConfirmProps} from 'react-confirm'

interface ConfirmModalProps {
  text: string
  title?: string
  className?: string
  acceptText?: string
  cancelText?: string
}

/**
 * Simple confirm modal with configurable content and accept and cancel callbacks.
 */
export const ConfirmModal = ({
  text,
  title = 'Confirm',
  show,
  proceed,
  dismiss,
  className,
  acceptText = 'Okay',
  cancelText = 'Cancel'
}: ConfirmModalProps & ReactConfirmProps) => (
  <Modal isOpen={show} toggle={dismiss} className={className} data-testid="confirm-modal">
    <ModalHeader toggle={dismiss}>{title}</ModalHeader>
    <ModalBody>{text}</ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={() => proceed()}>
        {acceptText}
      </Button>{' '}
      <Button color="secondary" onClick={dismiss}>
        {cancelText}
      </Button>
    </ModalFooter>
  </Modal>
)

ConfirmModal.propTypes = {
  text: PropTypes.any.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  acceptText: PropTypes.string,
  cancelText: PropTypes.string
}

export default confirmable<ConfirmModalProps>(ConfirmModal)
