export enum SortDirection {
  ASC = 'Ascending',
  DESC = 'Descending'
}

export type SortConfig<Item> = {
  id: string
  name: string
  getProperty: (item: Item) => number
}
