import React, {Component} from 'react'
import {Form, FormFeedback, Input} from 'reactstrap'
import styled from 'styled-components'

import Button from '../Button'
import VerticalMargin from '../../layout/Margins/Vertical'

const Icon = styled.i`
  color: blue;
  cursor: pointer;
  margin-left: 0.25rem;
`

export type EditableNumberProps = EditableNumberBaseProps | EditableNumberWithValidation

type EditableNumberBaseProps = {
  initialValue: number | undefined
  onSave: (value: number | undefined) => void | Promise<any>
  max?: number
  min?: number
  disabled?: boolean
}

const hasValidation = (props: EditableNumberBaseProps | EditableNumberWithValidation): props is EditableNumberWithValidation => {
  return (props as EditableNumberWithValidation).isValidFunc !== undefined
}

type EditableNumberWithValidation = EditableNumberBaseProps & EditableNumberValidationProps

type EditableNumberValidationProps = {
  isValidFunc: (value: number | undefined) => boolean
  invalidText: string
}

type EditableNumberState = {
  value: string
  editing: boolean
  isLoading: boolean
  errorMessage: string
}

const intToInputString = (n: number | undefined): string => {
  return n || n === 0 ? n.toString() : ''
}

const inputStringToInt = (str: string): number | undefined => {
  if (str === '') {
    return undefined
  } else if (isNaN(+str)) {
    return undefined
  } else {
    return +str
  }
}

const isBetweenMinAndMax = (value: string, min?: number, max?: number): boolean => {
  const isBelowMin = min ? parseFloat(value) < min : false
  const isAboveMax = max ? parseFloat(value) > max : false
  return !(isBelowMin || isAboveMax)
}

const minMaxErrorMessage = (min?: number, max?: number): string => {
  if (min && !max) return `Value must be at least ${min}`
  if (max && !min) return `Value must be equal or less than ${max}`
  if (max && min) return `Value must be between ${min} and ${max} (including)`
  return ''
}

export default class EditableNumber extends Component<EditableNumberProps, EditableNumberState> {
  state: EditableNumberState = {
    value: intToInputString(this.props.initialValue),
    editing: false,
    isLoading: false,
    errorMessage: ''
  }

  validate = (): {isValid: boolean; invalidText: string} => {
    const validations = [
      {
        isValid: isBetweenMinAndMax(this.state.value, this.props.min, this.props.max),
        invalidText: minMaxErrorMessage(this.props.min, this.props.max)
      },
      {
        isValid: hasValidation(this.props) ? this.props.isValidFunc(inputStringToInt(this.state.value)) : true,
        invalidText: hasValidation(this.props) ? this.props.invalidText : ''
      }
    ]

    const errors = validations.filter((validation) => !validation.isValid)
    return errors.length > 0 ? errors[0] : {isValid: true, invalidText: ''}
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({errorMessage: '', value: e.target.value})
  }

  save = async () => {
    this.setState({isLoading: true, errorMessage: ''})

    try {
      await this.props.onSave(inputStringToInt(this.state.value))
      this.setState({isLoading: false, editing: false})
    } catch (error) {
      this.setState({errorMessage: 'There was a problem when saving. Please try again.'})
      this.setState({isLoading: false})
    }
  }

  edit = () => {
    this.setState({editing: true})
  }

  cancel = () => {
    this.setState({editing: false, value: intToInputString(this.props.initialValue)})
  }

  render() {
    const {initialValue, disabled, min, max} = this.props
    const {value, editing, isLoading, errorMessage} = this.state
    const {isValid, invalidText} = this.validate()

    return (
      <div>
        {editing ? (
          <Form
            inline
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
              this.save()
            }}
          >
            <Input value={value} onChange={this.onChange} autoFocus min={min} max={max} invalid={!isValid} type="number" />
            <VerticalMargin margin={1} />
            <Button
              color={errorMessage ? 'danger' : 'primary'}
              onClick={this.save}
              isLoading={isLoading}
              disabled={inputStringToInt(value) === initialValue || !isValid || isLoading}
            >
              <i className="fa fa-check" title="Save" />
            </Button>
            <VerticalMargin margin={1} />
            <Button color="secondary" onClick={this.cancel} disabled={isLoading}>
              <i className="fa fa-close" title="Cancel" />
            </Button>

            {errorMessage && <FormFeedback style={{display: 'block'}}>{errorMessage}</FormFeedback>}
            {!isValid && <FormFeedback>{invalidText}</FormFeedback>}
          </Form>
        ) : (
          <span>
            {value.toString()} {!disabled && <Icon className="fa fa-edit" onClick={this.edit} />}
            {!isValid && <div className="text-danger small">{invalidText}</div>}
          </span>
        )}
      </div>
    )
  }
}
