import React, {useState} from 'react'
import styled from 'styled-components'
import Copy from 'react-copy-to-clipboard'
import PropTypes from 'prop-types'
import {UncontrolledTooltip} from 'reactstrap'

const ClipboardIcon = styled.i`
  margin-left: 5px;
  color: blue;
  cursor: pointer;
`

export type Props = {
  text: string
}

/**
 * `import {CopyToClipboard} from 'web-common-react-ts'`
 *
 * A button that copies the provided text to the user's clipboard on click.
 */
const CopyToClipboard = ({text}: Props) => {
  const [copied, setCopied] = useState(false)
  // base 64 encode then remove + / = to ensure a valid html ID
  const encode = (s: string) => btoa(s).replace(/[+=\/]/g, '')
  const truncate = (s: string) => s.slice(0, 10)
  const id = `copyToClipboard-${truncate(encode(text))}`
  return (
    <Copy text={text} onCopy={() => setCopied(true)}>
      <span data-testid="copy-to-clipboard">
        <ClipboardIcon className="fa fa-clipboard" id={id} />
        <UncontrolledTooltip target={id} placement="bottom">
          {copied ? 'Copied' : 'Copy to clipboard'}
        </UncontrolledTooltip>
      </span>
    </Copy>
  )
}

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired
}

export default CopyToClipboard
