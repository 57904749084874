import {GraphQLClient, gql} from 'graphql-request'
import {toast} from 'react-toastify'

import ApiError from '../../errors/ApiError'
import {refreshToken} from '../../service'
import {getAuthHeaders} from './interceptors'

interface ServiceOptions {
  baseUrl: string
}

interface CallOptions<RequestVariables> {
  query: string
  variables?: {[key: string]: any}
  headers?: RequestVariables
  validateStatus?: (status: number) => boolean
  transformResponse?: (data: any, headers?: any) => any
  transformRequest?: (data: any, headers?: any) => any
}

export type Service = {
  query: <RequestVariables, ResponseData>(options: CallOptions<RequestVariables>) => Promise<ResponseData>
  mutation: <RequestVariables, ResponseData>(options: CallOptions<RequestVariables>) => Promise<ResponseData>
}

const errorMessages: {[statusCode: number]: string} = {
  401: 'You are not authorised to do this operation',
  403: 'You are not authorised to do this operation'
}

export const service = ({baseUrl}: ServiceOptions): Service => {
  const client = new GraphQLClient(baseUrl)

  const request = async <RequestVariables extends {} | undefined, ResponseData extends {} | undefined>(
    options: CallOptions<RequestVariables>,
    attempt: number = 0
  ): Promise<ResponseData> => {
    try {
      const authHeaders = await getAuthHeaders(baseUrl)
      const {query, variables = {}, headers = {}} = options

      const response = await client.rawRequest(
        gql`
          ${query}
        `,
        variables,
        {...headers, ...authHeaders}
      )

      if (!response.data && response.errors) {
        throw new ApiError({message: response.errors[0].message})
      } else {
        return response.data
      }
    } catch (error) {
      if (error.status === 401 && attempt === 0) {
        try {
          await refreshToken()
          const response = await request(options, attempt + 1)
          // @ts-ignore
          return response
        } catch (error) {
          throw new ApiError({message: error.message})
        }
      }

      if (error.response && errorMessages[error.response.status]) {
        toast.error(errorMessages[error.response.status])
      }

      throw new ApiError({message: error.message})
    }
  }

  return {
    // They're just aliases for now, but we might want to extend
    query: <RequestVariables extends {} | undefined, ResponseData extends {} | undefined>(options: CallOptions<RequestVariables>) =>
      request<RequestVariables, ResponseData>(options),
    mutation: <RequestVariables extends {} | undefined, ResponseData extends {} | undefined>(options: CallOptions<RequestVariables>) =>
      request<RequestVariables, ResponseData>(options)
  }
}
