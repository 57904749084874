import React, {FC, useRef, useState} from 'react'
import styled from 'styled-components'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupButtonDropdown
} from 'reactstrap'

import Button from '../../ui/Button'
import zIndexes from '../../constants/zIndexes'

export type SearchOption = {
  id: string
  title: string
  placeholder: string
  searchAction: (term: string) => Promise<any>
  show?: boolean
}

export type InitialSearch = {
  optionId: string
  searchTerm?: string
}

export enum SearchFormSize {
  sm = 'sm',
  lg = 'lg'
}

export interface Props {
  searchOptions: Array<SearchOption>
  size?: SearchFormSize
  initialSearch?: InitialSearch
  clearResults?: () => void
  buttonText?: string
}

const SearchFormContainer = styled(FormGroup)`
  position: relative;
  z-index: ${zIndexes.top + 50};
`

const SearchForm: FC<Props> = ({searchOptions, clearResults, initialSearch, buttonText = 'Go', size = SearchFormSize.sm}: Props) => {
  const initialSearchOption = (initialSearch && searchOptions.find(({id}) => id === initialSearch.optionId)) || searchOptions[0]
  const initialSearchTerm = (initialSearch && initialSearch.searchTerm) || ''
  const [isOpen, setOpen] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm)
  const [selectedOption, setSelectedOption] = useState<SearchOption>(initialSearchOption)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const showSearchOptionsToggle = searchOptions.length > 1

  const inputBoxRef = useRef<HTMLInputElement>(null)

  const toggleOpen = () => setOpen(!isOpen)

  return (
    <Form
      data-testid="search-form"
      onSubmit={async (e) => {
        e.preventDefault()
        setIsSearching(true)

        try {
          await selectedOption.searchAction(searchTerm)
        } finally {
          setIsSearching(false)
        }
      }}
    >
      <SearchFormContainer>
        <InputGroup size={size}>
          {showSearchOptionsToggle && (
            <InputGroupButtonDropdown addonType="prepend" isOpen={isOpen} toggle={toggleOpen}>
              <DropdownToggle data-testid="search-form-options-selected" caret>
                {selectedOption.title}
              </DropdownToggle>

              <DropdownMenu data-testid="search-form-options">
                <DropdownItem header>Search options</DropdownItem>
                {searchOptions
                  .filter(({show}) => show !== false)
                  .map((option: SearchOption) => (
                    <DropdownItem
                      key={option.id}
                      onClick={() => {
                        if (option.id !== selectedOption.id) {
                          clearResults && clearResults()
                          setSelectedOption(option)
                        }
                        if (inputBoxRef.current) {
                          inputBoxRef.current.focus()
                        }
                      }}
                      active={option.title === selectedOption.title}
                    >
                      {option.title}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </InputGroupButtonDropdown>
          )}

          <Input
            data-testid="search-form-input"
            bsSize={size}
            placeholder={selectedOption.placeholder}
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            autoFocus={true}
            innerRef={inputBoxRef}
          />

          <InputGroupAddon addonType="append">
            <Button
              data-testid="search-form-button"
              color="primary"
              type="submit"
              disabled={!searchTerm || isSearching}
              isLoading={isSearching}
            >
              {buttonText}
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </SearchFormContainer>
    </Form>
  )
}

export default SearchForm
