import {toast} from 'react-toastify'
import {Auth} from 'aws-amplify'

import {service} from './api/ApiUtils'
import {Permissions} from './model'
import {saveToken} from './api/ApiUtils/interceptors'

let refreshTokenData: {
  apiEndpoint: string
  game: string
  environment: string
}

export const login = async (apiEndpoint: string, game: string, environment: string): Promise<Permissions> => {
  try {
    const response = await service({baseUrl: apiEndpoint}).get({
      url: '/v1/login',
      params: {game, environment}
    })

    const newToken = response.headers && (response.headers['x-sag-authorization'] || response.headers['X-SAG-Authorization'])

    if (newToken) {
      saveToken(newToken)
    }

    refreshTokenData = {apiEndpoint, game, environment}

    return response.data
  } catch (error) {
    toast.error('Log in failed')
    throw error
  }
}

export const refreshToken = async (): Promise<Permissions> => {
  if (!refreshTokenData) throw new Error('Refresh token data has not been populated yet')

  try {
    const response = await login(refreshTokenData.apiEndpoint, refreshTokenData.game, refreshTokenData.environment)
    return response
  } catch (error) {
    toast.error('Session refresh failed. Please logout and log back in.')
    await Auth.signOut()
    throw error
  }
}
