import React, {ReactNode, useEffect, useState} from 'react'
import styled, {css} from 'styled-components'
import {Card, CardBody, CardHeader, Collapse} from 'reactstrap'
import Colors from '../../constants/colors'
import {sm, xs} from '../../constants/spacing'

type Theme = 'normal' | 'compact'

export interface Props {
  header: ReactNode
  children: ReactNode
  isExpandedOverride?: boolean
  theme?: Theme
}

const Header = styled(CardHeader)<{theme: Theme}>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  flex-wrap: wrap;
  align-items: center;

  ${(props) =>
    props.theme === 'compact'
      ? css`
          padding: ${xs}px ${sm}px;
        `
      : ''}
`

const Arrow = styled.i`
  color: ${Colors.grey};
  margin-left: auto;
`

const Body = styled(CardBody)<{theme: Theme}>`
  ${(props) =>
    props.theme === 'compact'
      ? css`
          padding: ${sm}px;
        `
      : ''}
`

function ExpandableCard({header, children, isExpandedOverride = false, theme = 'normal'}: Props) {
  const [isExpanded, setIsExpanded] = useState(isExpandedOverride)
  const iconClassName = `fa fa-caret-${isExpanded ? 'up' : 'down'}`

  useEffect(() => {
    setIsExpanded(isExpandedOverride)
  }, [isExpandedOverride])

  const toggle = () => setIsExpanded(!isExpanded)

  return (
    <Card>
      <Header onClick={toggle} theme={theme}>
        {header}
        <Arrow className={iconClassName} />
      </Header>

      <Collapse data-testid="expandable-card-content" isOpen={isExpanded}>
        <Body theme={theme}>{children}</Body>
      </Collapse>
    </Card>
  )
}

export default ExpandableCard
