import React from 'react'
import styled from 'styled-components'

import Colors from '../../constants/colors'
import {md, xs} from '../../constants/spacing'
import zIndexes from '../../constants/zIndexes'
import CopyToClipboard from '../CopyToClipboard'

export interface Props {
  content: string
  allowCopy: boolean
}

const Wrapper = styled.div`
  position: relative;
  background-color: ${Colors.light};
  border: ${Colors.lightGrey} 1px solid;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`

const Code = styled.pre`
  position: relative;
  color: ${Colors.grey};
  margin: 0;
  overflow: auto;
  max-height: 300px;
  padding: ${md}px;
  z-index: ${zIndexes.middle};
`

const Copy = styled.div`
  position: absolute;
  top: -${xs}px;
  right: -${xs}px;
  padding: ${xs}px;
  background-color: ${Colors.light};
  border: ${Colors.lightGrey} 1px solid;
  z-index: ${zIndexes.middle + 10};
`

/**
 * Displays provided content in a code monofont. No support for syntax highlighting yet, but we can do this
 * at any point if we need to.
 */
function CodeBlock({content, allowCopy = false}: Props) {
  return (
    <Wrapper>
      {allowCopy && (
        <Copy>
          <CopyToClipboard text={content} />
        </Copy>
      )}

      <Code>{content}</Code>
    </Wrapper>
  )
}

export default CodeBlock
