import {useEffect, useState} from 'react'
import useIsMounted from 'ismounted'

type UseGetResult<ResponseData> = {
  load: () => Promise<void>
  resetState: () => void
  loading: boolean
  error: Error | undefined
  done: boolean
  data: ResponseData | undefined
}

type UsePostResult<ResponseData = undefined, RequestData = undefined> = {
  post: (data?: RequestData) => Promise<void>
  resetState: () => void
  loading: boolean
  done: boolean
  error: Error | undefined
  data: ResponseData | undefined
}

interface UseGetOptions {
  loadOnStart?: boolean
}

export const useGet = <ResponseData extends {}>(
  apiHandler: () => Promise<ResponseData>,
  {loadOnStart = true}: UseGetOptions = {}
): UseGetResult<ResponseData> => {
  const isMounted = useIsMounted()
  const [data, setData] = useState<ResponseData | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [done, setDone] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  const resetState = () => {
    setData(undefined)
    setLoading(false)
    setDone(false)
    setError(undefined)
  }

  const load = async () => {
    setDone(false)
    setLoading(true)
    setError(undefined)

    try {
      const response = await apiHandler()

      if (isMounted.current) {
        setLoading(false)
        setDone(true)
        setData(response)
      }
    } catch (error) {
      if (isMounted.current) {
        setLoading(false)
        setError(error)
      }
    }
  }

  useEffect(() => {
    if (loadOnStart) {
      load()
    }
  }, [])

  return {load, data, loading, done, error, resetState}
}

export const usePost = <ResponseData extends {} | undefined | void = undefined, RequestData extends {} | undefined = undefined>(
  apiHandler: (data?: RequestData) => Promise<ResponseData>
): UsePostResult<ResponseData, RequestData> => {
  const isMounted = useIsMounted()
  const [data, setData] = useState<ResponseData | undefined>()
  const [done, setDone] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  const resetState = () => {
    setData(undefined)
    setLoading(false)
    setDone(false)
    setError(undefined)
  }

  const post = async (data?: RequestData) => {
    setLoading(true)
    setDone(false)
    setError(undefined)

    try {
      const response = await apiHandler(data)

      if (isMounted.current) {
        setLoading(false)
        setDone(true)
        setData(response)
      }
    } catch (error) {
      if (isMounted.current) {
        setLoading(false)
        setError(error)
      }
    }
  }

  return {post, data, loading, done, error, resetState}
}
