import React, {useContext} from 'react'

import {Permissions, AuthInfo} from '../../model'

type Context = {
  permissions: Permissions
  authInfo?: AuthInfo
}

export const AuthContext = React.createContext<Context>({permissions: [], authInfo: undefined})

export const useAuth = () => useContext(AuthContext)

export default {
  Consumer: AuthContext.Consumer,
  Provider: AuthContext.Provider
}
