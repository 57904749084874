import {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios'
import {Auth} from 'aws-amplify'
import {CognitoUserSession} from 'amazon-cognito-identity-js'

let sagAuthToken: string | undefined

export const getGameHubAuthHeadersForLocalhost = (url: string, session: CognitoUserSession) => {
  // if we're talking to localhost, then we won't be going through the proxy, so we
  // have to provide the X-SAG headers ourselves (normally this is done for us, by the proxy)
  if (url.startsWith('http://localhost') || url.startsWith('https://localhost')) {
    const idTokenPayload = session.getIdToken().payload

    return {
      'X-SAG-Name': idTokenPayload.name,
      'X-SAG-UserName': idTokenPayload['cognito:username'],
      'X-SAG-Email': idTokenPayload.email,
      'X-SAG-Groups': idTokenPayload['cognito:groups']
    }
  } else {
    return {}
  }
}

export const getSAGAuthHeader = () => {
  if (sagAuthToken) {
    return {
      'X-SAG-Authorization': sagAuthToken
    }
  }

  return {}
}

export const getAuthHeaders = async (baseUrl?: string, useAuthHeaders: boolean = true) => {
  const session = await Auth.currentSession()

  let headers = {
    Authorization: session.getIdToken().getJwtToken()
  }

  if (useAuthHeaders && baseUrl) {
    headers = {
      ...headers,
      ...getGameHubAuthHeadersForLocalhost(baseUrl, session),
      ...getSAGAuthHeader()
    }
  }

  return headers
}

export const saveToken = (newToken: string) => {
  sagAuthToken = newToken
}

export const authRequestInterceptor = (useAuthHeaders: boolean) => ({
  success: async (config: AxiosRequestConfig) => {
    const headers = await getAuthHeaders(config.baseURL, useAuthHeaders)

    const requestConfig = {
      ...config,
      headers: {
        ...config.headers,
        ...headers
      }
    }

    return requestConfig
  }
})

export const responseErrorInterceptor = () => ({
  success: (response: AxiosResponse) => response,
  error: async (error: AxiosError) => {
    const request: XMLHttpRequest = error.request

    if (error.request) {
      console.warn(`Got an error from the server for URL: ${request.responseURL}. Check to see if this service is setup properly.`)
    }

    return Promise.reject(error)
  }
})
