import {useState} from 'react'
import sort from 'ramda/src/sort'

import {SortConfig, SortDirection} from '../types'

type useSortResult<Item> = {
  sortData: (sortableData: Item[]) => Item[]
  selectedSort: SortConfig<Item>
  sortDirection: SortDirection
  selectSort: (sortId: string) => void
  toggleDirection: () => void
}

const initialSortDirection = SortDirection.DESC

const cleanProperty = (property: any): number => (!!property || property === 0 ? property : -Infinity)

function useSort<Item>(sortConfig: SortConfig<Item>[]): useSortResult<Item> {
  const [sortDirection, setSortDirection] = useState<SortDirection>(initialSortDirection)
  const [selectedSort, setSelectedSort] = useState<SortConfig<Item>>(sortConfig[0])

  const sortData = (sortableData: Item[]): Item[] => {
    if (!selectedSort) return sortableData

    const sorter =
      sortDirection === SortDirection.ASC
        ? (a: Item, b: Item) => cleanProperty(selectedSort.getProperty(a)) - cleanProperty(selectedSort.getProperty(b))
        : (a: Item, b: Item) => cleanProperty(selectedSort.getProperty(b)) - cleanProperty(selectedSort.getProperty(a))

    return sort(sorter, sortableData)
  }

  const toggleDirection = () => {
    setSortDirection(sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC)
  }

  const selectSort = (sortId: string) => {
    const propertyConfig = sortConfig.find((config) => config.id === sortId)

    if (!propertyConfig) {
      throw new Error(`Unable to find sort config with id "${sortId}"`)
    }

    if (selectedSort?.id !== propertyConfig.id) {
      setSelectedSort(propertyConfig)
    }
  }

  return {sortDirection, selectedSort, sortData, selectSort, toggleDirection}
}

export default useSort
