import React from 'react'
import styled, {css} from 'styled-components'
import {Spinner as ReactstrapSpinner} from 'reactstrap'

/**
 * Loading spinner using reactstrap.
 */
const Container = styled.div<{$fullPage: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.$fullPage
      ? css`
          min-width: 100vw;
          min-height: 100vh;
        `
      : ''}
`

type Size = 'sm' | 'md'

export interface Props {
  size?: Size
  fullPage?: boolean
}

const Spinner: React.FC<Props> = ({size, fullPage = false}) => (
  <Container $fullPage={fullPage}>
    <ReactstrapSpinner size={size} title="Loading" />
  </Container>
)

export default Spinner
