interface ErrorData {
  errorCode?: string
  status?: number
  message: string
}

class ApiError extends Error {
  response: ErrorData

  constructor(data: ErrorData, ...params: any[]) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError)
    }

    this.name = 'CustomError'

    this.response = data
    this.message = data.message || 'Generic error'
  }
}

export default ApiError
