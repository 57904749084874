import React from 'react'
import styled from 'styled-components'

import zIndexes from '../../constants/zIndexes'

const AppFooter = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f4770f;
  padding: 0.5rem 1rem;
  color: white;
  text-align: center;
  z-index: ${zIndexes.top + 100};
`

type FooterProps = {
  versions?: [string, string][]
}

const Footer: React.FC<FooterProps> = ({versions, children}) => (
  <AppFooter>
    {versions
      ? versions.map(([name, version], index) => {
          return (
            <span key={`${name}-${version}`}>
              <strong>{name}: </strong>
              {version} {index < versions.length - 1 && '|'}{' '}
            </span>
          )
        })
      : ''}
    {children}
  </AppFooter>
)

export default Footer
